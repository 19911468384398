// React
import React, { useState, useEffect } from "react"

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import Axios from "axios"
import { JenisCustomerApi } from "../../../api"

// Component
import { Modal, Col, Row, ButtonGroup } from "react-bootstrap"
import {
  CRUDLayout, InputSearch, CreateButton, UpdateButton,
  ActionButton, DataStatus, Tr, Th, Td, DeleteButton,
  DeleteModal, Alert, Input, TextArea, Pagination,
  Switch, THead, TBody, ThFixed, TdFixed,
} from "../../../components"
import { PageNumber } from "../../../utilities"

const JenisCustomer = ({ setNavbarTitle }) => {
  const title = "Jenis Customer"
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true)

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  // Modal
  const [modal, setModal] = useState({
    show: false,
    type: '',
    data: {}
  })

  // data jenis anggaran
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [dataCount, setDataCount] = useState(0)

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false)
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // request data dari server
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)
    setShowAlert(false)

    // request data ke server
    Axios.all([
      JenisCustomerApi.getPage(page, dataLength, searchKey)
    ])
      .then(
        Axios.spread(res => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setDataCount(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        }
        setIsLoading(false)
      })
  }

  // fungsi show/ hide
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setShowAlert(false)

    const value = {
      id_jenis_customer: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? JenisCustomerApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : JenisCustomerApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title)

    // jalankan function request data ke server
    getData()

    // eslint-disable-next-line
  }, [setNavbarTitle, page, dataLength, searchKey])

  const DataModal = () => {
    const formInitialValues = {
      kode_jenis_customer: modal?.data?.kode_jenis_customer,
      nama_jenis_customer: modal?.data?.nama_jenis_customer,
      keterangan: modal?.data?.keterangan
    }

    const formValidationSchema = Yup.object().shape({
      kode_jenis_customer: Yup.string()
        .required("Kode Jenis Customer wajib diisi")
        .test('checkKode', 'Kode Jenis Customer telah didaftarkan', value => modal?.data?.kode_jenis_customer === value
          ? true
          : JenisCustomerApi.single({ kode_jenis_customer: value })
            .then(() => false)
            .catch(() => true)
        ),
      nama_jenis_customer: Yup.string().required("Nama Jenis Customer wajib diisi"),
    })

    const formSubmitHandler = (values) => {
      if (modal.type === 'tambah') {
        JenisCustomerApi.create(values)
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "primary",
              text: "Tambah data berhasil!",
            })
          })
          .catch((err) => {
            // konfigurasi alert
            setAlertConfig({
              variant: "danger",
              text: `Tambah data gagal! (${err})`,
            })
          })
          .finally(() => {
            // menutup modal
            setModal({
              ...modal,
              show: false
            })
            // menampilkan alert
            setShowAlert(true)
            // request data baru ke server
            getData()
          })
      } else {
        JenisCustomerApi.update({
          id_jenis_customer: modal?.data?.id_jenis_customer,
          ...values
        })
          .then(() => {
            // konfigurasi alert
            setAlertConfig({
              variant: "primary",
              text: "Ubah data berhasil!",
            })
          })
          .catch((err) => {
            // konfigurasi alert
            setAlertConfig({
              variant: "danger",
              text: `Ubah data gagal! (${err})`,
            })
          })
          .finally(() => {
            // menutup modal
            setModal({
              ...modal,
              show: false
            })
            // menampilkan alert
            setShowAlert(true)
            // request data baru ke server
            getData()
          })
      }
    }

    return (
      <Modal
        show={modal?.show && modal?.type !== 'hapus'}
        onHide={() => setModal({
          ...modal,
          show: false
        })}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h6 className="mb-0 text-capitalize">{modal.type} {title}</h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Jenis Customer"
                  type="text"
                  name="kode_jenis_customer"
                  placeholder="Kode Jenis Customer"
                  value={values.kode_jenis_customer}
                  onChange={handleChange}
                  error={errors.kode_jenis_customer && touched.kode_jenis_customer && true}
                  errorText={errors.kode_jenis_customer}
                />

                <Input
                  label="Jenis Customer"
                  type="text"
                  name="nama_jenis_customer"
                  placeholder="Jenis Customer"
                  value={values.nama_jenis_customer}
                  onChange={handleChange}
                  error={errors.nama_jenis_customer && touched.nama_jenis_customer && true}
                  errorText={errors.nama_jenis_customer}
                />

                <TextArea
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  rows={3}
                />
              </Modal.Body>

              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    )
  }

  // Modal Hapus
  const HapusModal = () => {
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false)

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true)

      JenisCustomerApi.delete({ id_jenis_customer: modal?.data?.id_jenis_customer })
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err})`,
          })
        })
        .finally(() => {
          // menutup modal
          setModal({
            ...modal,
            show: false
          })
          // menampilkan alert
          setShowAlert(true)
          // request data baru ke server
          getData()
        })
    }

    return (
      <DeleteModal
        show={modal?.show && modal?.type === 'hapus'}
        onHide={() => setModal({
          ...modal,
          show: false
        })}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Jenis Customer : {modal?.data?.kode_jenis_customer}</div>
        <div>Nama Jenis Customer : {modal?.data?.nama_jenis_customer}</div>
      </DeleteModal>
    )
  }

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>Kode</ThFixed>
            <Th>Nama Jenis Customer</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup>
                    <UpdateButton
                      onClick={() => setModal({
                        show: true,
                        type: 'ubah',
                        data: val
                      })}
                    />
                    <DeleteButton
                      onClick={() => setModal({
                        show: true,
                        type: 'hapus',
                        data: val
                      })}
                    />
                  </ButtonGroup>

                  <Switch
                    id={toString(index + 1)}
                    checked={val.is_hidden === false ? true : false}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_jenis_customer)}
                  />
                </div>
              </TdFixed>
              <TdFixed>{val.kode_jenis_customer}</TdFixed>
              <Td>{val.nama_jenis_customer}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1)
            setDataLength(e.target.value)
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  )

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        {/* Search Section */}
        <CRUDLayout.HeadSearchSection>
          {/* Search Form */}
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value)
                    setPage(1)
                  }, 1000)
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton onClick={() => setModal({
            show: true,
            type: 'tambah',
            data: {}
          })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table Section */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
          data.length > 0 ? (
            // Ada data
            <Table />
          ) : (
            // Tidak ada data
            <DataStatus text="Tidak ada data" />
          )
      }

      {/* Modal */}
      <DataModal />
      <HapusModal />
    </CRUDLayout>
  )
}

export default JenisCustomer
