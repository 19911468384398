import React from "react"
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import ReactSwitch from "react-switch"
import { GuestMiddleware } from "utilities/GuestMiddleware"

const Switch = ({ id, checked, onChange }) => {
  return (
    <GuestMiddleware>
      <OverlayTrigger
        overlay={
          <Tooltip>
            {checked ? 'Aktif' : 'Tidak aktif'}
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center mx-1">
          <ReactSwitch
            id={id}
            checked={checked}
            onChange={onChange}
            draggable={false}
            onColor="#3B82F6"
          />
        </div>
      </OverlayTrigger>
    </GuestMiddleware>
  )
}

export default Switch
